<template>
  <div>
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120" />
      </div>
    </div>
    <div class="m-6">
      <slot>
        <div>
          <h4 class="h4">Are you sure you want to remove this item?</h4>
        </div>
        <div class="mt-4">
          <p>This action cannot be undone.</p>
        </div>
      </slot>
      <!--SAVE AND CANCEL BUTTONS-->
      <div class="flex justify-between items-center mt-16">
        <merge-button-round
          :disabled="isLoading"
          button-type="secondary"
          @click="$emit('close')"
        >
          <p>No, cancel</p>
        </merge-button-round>
        <merge-button-round
          :disabled="isLoading"
          class="bg-bb-error"
          button-type="primary"
          @click="$emit('remove')"
        >
          <p>{{ removeButtonText }}</p>
        </merge-button-round>
      </div>
    </div>
  </div>
</template>

<script>
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader'

export default {
  name: 'RemoveItemModal',
  components: {
    MergeButtonRound,
    BrightbidLoader,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    removeButtonText: {
      type: String,
      default: 'Yes, remove',
    },
  },
}
</script>

<style scoped></style>
